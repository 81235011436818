// import http from "@/utils/request";
import dmshttp from "@/utils/dmsrequest";
// 分销采购退货列表
export function getPurchaseReturnList(data) {
  return dmshttp({
    url: 'getPurchaseReturnList',
    data
  })
}
// 退货详情 /purchaseReturn/purchaseReturnEntry
export function purchaseOrderReturnDetail(data) {
  return dmshttp({
    url: 'purchaseOrderReturnDetail',
    data
  })
}
// 正品退货列表
export function distributorReturnOrderList(data) {
  return dmshttp({
    url: 'distributorReturnOrderList',
    data
  })
}




export function getList(data) {
  return dmshttp({
    url: 'agentReturnOrderList',
    data
  })
}

export function batch(data) {
  return dmshttp({
    url: 'batchInvalidSalesOrder',
    data
  })
}


// 驳回
export function orderReject(data) {
  return dmshttp({
    url: 'rejectPurchaseOrder',
    data
  })
}



//取消审核(
export function cancleReject(data) {
  return dmshttp({
    url: 'deleteReserverBySoId',
    data
  })
}

// 导出

export function exportOrder(data) {
  return dmshttp({
    url: 'exportPurchaseReturnList' + '&type=1',
    data,
    responseType: 'blob'
  })
}
export function exportDistributorReturnOrderList(data) {
  return dmshttp({
    url: 'exportDistributorReturnOrderList' + '&type=1',
    data,
    responseType: 'blob'
  })
}


// 分銷商按单退货
export function retrunOrderQuest(data) {
  return dmshttp({
    url: 'purchaseCreationReturn',
    data
  })
}

export function salesReturn(data) {
  return dmshttp({
    url: 'cancelReturn',
    data
  })
}
// 销售出库单明细查询
export function getOrderOutList(data) {
  return dmshttp({
    url: "agentReturnOrderDetail",
    data
  });
}



// 获取出库表格信息
export function getTableList(data) {
  return dmshttp({
    url: 'getWarehouseList',
    data
  })
}

// 点击入库
export function saleOrderOut(data) {
  return dmshttp({
    url: 'agentReturnOrderInbound',
    data
  })
}
// 判断是否启用补差类型
export function difference(data) {
  return dmshttp({
    url: 'isEnableOrNot',
    data
  })
}