<template>
  <a-modal
    v-model="visible"
    @ok="handleOk"
    @cancel="cancle"
    wrapClassName="cancelName"
    :width='648'
    :closable="false"
    centered
    :maskClosable='false'
    :ok-button-props="{ props: { disabled: submitBtn } }"
  >
    <div class="modal_close" @click="cancle">
      <span class="iconfont icon-danchuangguanbi-anniu modal_close_icon"></span>
    </div>
    <div class="modal_title_box">
      <span class="modal_title">
        <i class="modal_title_line"></i>申请取消
      </span>
    </div>
    <div class="cancelContent">
<!--      <p class="messageInfo">请在发起说明处填写真实情况</p>-->
    </div>
    <div class="cancelInfo">
      <span class="cancleZi"><span style="color:#D9161C;">*</span>取消说明:</span>
      <a-textarea placeholder="请输入取消说明" allow-clear  @change="textChange" :value="textarea" v-model="textarea"/>
    </div>
  </a-modal>
</template>
<script>
export default {
  // 示例
  // <cancelreason-modal
  // :visible.sync="visible"
  // info='提示文字'
  // ></message-modal>

  name:'cancel-modal',
  data(){
    return{
      submitBtn:false,
      selsetClear:false,
      list:[],
      reason:'',
      textarea:''.trim(),
      form: this.$form.createForm(this, { name: 'advanced_search' }),
    }
  },
  props:{
    visible:{
      type:Boolean,
      default:false
    },
  },
 
  methods:{
    textChange(e){
      if( (e.target.value).trim()){
        this.submitBtn = false
      }
      this.textarea = this.textarea.trim()
      this.reason = (e.target.value).trim()
    },

    handleOk(){
      if (!this.reason ) {
        this.$message.warning('请输入取消原因')
        return
      }
      this.$emit('update:visible',false)
      this.$emit('cancelReasonHandOk',this.reason)
      this.textarea = ''
      this.reason = ''
      this.form.resetFields();

    },
    cancle(){
      this.$emit('update:visible',false)
      this.textarea = ''
      this.reason = ''
      this.form.resetFields();
    }

  }
}
</script>
<style lang="less" scoped>
/deep/ .cancelName{
 .ant-modal-content{
    text-align:center;
    border-radius: 10px;
    .ant-modal-body{
      padding:30px 0px 0 0px;
      .cancelContent{
        text-align: left;
        padding:0 40px 0 0;
        font-size: 14px;
        font-weight: 400;
        color: #262626;
        line-height: 20px;
        .ant-tree-node-selected {
          background-color: red;
        }
        .ant-form {
          line-height: 32px;
        }
        .ant-form-item-control-wrapper{
          margin-left:16px;
        }
        .ant-form-item-control{
          line-height: 32px;
        }
        .ant-form-item-label{
          line-height: 32px;
        }
        .ant-form-item{
          margin-bottom:0;
        }
        .messageInfo{
          font-size: 12px;
          font-weight: 400;
          color: #AAAAAA;
          line-height: 12px;
          margin-top:8px;
          margin-left:86px;
        }
      }
      .cancelInfo{
        padding:14px 0 50px 74px;
        text-align: left;
        overflow: hidden;
        .cancleZi{
          width: 78px;
          height: 20px;
          font-size: 14px;
          font-weight: 400;
          color: #262626;
          line-height: 20px;
          float:left;
          padding-left:11px;
          margin-right:9px;
          margin-top: 8px;
        }
        .ant-input{
          width: 400px;
          height:88px;
          overflow: auto;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }
        .ant-input-affix-wrapper{
          width: 400px;
        }
      }
      .modal_close {
        position: absolute;
        top: 0;
        right: 0;
        width: 42px;
        height: 42px;
        line-height: 42px;
        border-radius: 0 10px 0 0;
        text-align: center;
        cursor: pointer;
        .modal_close_icon {
          color: #aaa;
          font-size: 16px;
        }
      }
      .modal_close:hover {
        background: #E4F8F7;
        .modal_close_icon {
          color: #08ABA8;
        }
      }
      .modal_title_box {
        text-align: center;
        padding-bottom:50px;
        .modal_title {
          position: relative;
          color: #262626;
          text-align: center;
          z-index: 10;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing:1px;
          .modal_title_line {
            display: inline-block;
            width: 100%;
            position: absolute;
            top: 13px;
            left: 0;
            border-top: 10px solid #E4F8F7;
            z-index: -10;
          }
        }
      }
    }
    p{
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #262626;
      line-height: 30px;
    }
    .ant-modal-footer{
      text-align: center;
      border-top:0px solid #fff;
      padding:0px 0 30px;
      .ant-btn{
        width: 120px;
        height:40px;
        border-radius: 4px;
        font-size: 16px;
        color:#777;
      }
      .ant-btn-primary{
        color: #fff;
        background:#00AAA6;
        margin-left:24px;
      }
    }
  }
}
</style>


